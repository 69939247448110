//$image-path:                   "../img/elements/"!default;
$image-theme-base-path:        "../img/"!default;
$font-theme-base-path  :		"../fonts/" !default;

// Variables paths 
// ========================================================================

$font-family-primary:			 		'Heebo', sans-serif !default;
$font-family-secondary :       			'ShonarBangla';
$font-family-tertiary  :       			'ShonarBangla bold' !default;



//////////////////// Variables defined for Themes + Skins ////////////////

$theme-color-primary:  				#f4f4f4 !default;
$theme-color-secondary:       		#ffffff !default;
$theme-color-tertiary:        		#d8a464 !default;

$text-color-primary: 				#363636 !default;
$text-color-secondary: 				#d8a464 !default;
$text-color-tertiary: 				#999999 !default;

//font icon
$font-icon: 			             "Material Icons" !default;
$font-icon-secondary: 			    "FontAwesome" !default;


// Variables
// --------------------------------------------------
$rtl-left : left!default;
$rtl-right : right!default;


