$gray-darker: #414141;
$gray-dark: #878787;
$gray: #acaaa6;
$gray-light: #ebebeb;
$gray-lighter: #f6f6f6;
$brand-primary: #293f85;
$brand-secondary: #f39d72;
$brand-success: #4cbb6c;
$brand-warning: #ff9a52;
$brand-danger: #ff4c4c;
$label-color: $gray-darker;
$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: transparent;
$btn-secondary-color: $gray-darker;
$btn-secondary-bg: $gray-lighter;
$btn-secondary-border: transparent;
$btn-border-radius: 0;
$headings-font-weight: 700;
$tooltip-bg: $brand-danger;
$border-radius: 0;
$extra-small-space: rem(5px);
$small-space: rem(10px);
$medium-space: rem(20px);
$large-space: rem(30px);
$extra-large-space: rem(15px);
$warning: #FF9A52;
$warning-hover: #FDE7BB;
$alert-warning-bg: rgba(255,154,82,0.3);
$alert-warning-border: $warning;
$alert-warning-text: $gray-darker;
$btn-warning-bg: $warning;
$btn-warning-color: white;
$btn-tertiary-color: #6C868E;
$btn-tertiary-hover: #BBCDD2;
$base-font-size: rem(16px);
$font-size-lg: rem(15px);
$font-size-sm: rem(14px);
$font-size-xs: rem(13px);
$font-size-h1: rem(22px);
$font-size-h2: rem(20px);
$font-size-h3: rem(18px);
$font-size-h4: rem(18px);
$font-size-h5: rem(16px);
$font-size-h6: rem(15px);
$display1-size: rem(50px);
$display1-weight: 600;
$display2-size: rem(35px);
$display2-weight: 400;
$display3-size: rem(25px);
$display3-weight: 400;
$display4-size: rem(20px);
$display4-weight: 400;
$text-muted: $gray;
$breadcrumb-bg: #efefef;
$breadcrumb-padding-y:          rem(15px);
$breadcrumb-padding-x:          0;
