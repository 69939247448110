

// Mega Menu Module
// ========================================================================
$megamenu-outside-bg :	 						transparent !default;
$megamenu-bg: 									#ffffff !default;
$megamenu-color: 				    			#363636 !default;
$megamenu-font-family:              			$font-family-primary !default;
$megamenu-link-color: 							#363636 !default;
$megamenu-link-hover-color:         			$text-color-secondary !default;
$megamenu-link-font-size:           			14px  !default;
$megamenu-font-weight:           				700  !default;

$megamenu-parent-padding: 						10px 20px !default;
$megamenu-parent-text-transform: 				uppercase !default;


$megamenu-sub-bg-color: 						#fff !default;
$megamenu-sub-font-family:          			$font-family-base !default;
$megamenu-sub-text-color:           			#363636 !default;
$megamenu-sub-link-color: 						#363636 !default;
$megamenu-sub-link-hover-color: 				$text-color-secondary !default;
$megamenu-sub-font-size:            			$font-size-base !default;


$megamenu-heading-font-family: 					$font-family-primary !default;
$megamenu-heading-font-size:					16px!default;
$megamenu-heading-color:						#363636!default;

