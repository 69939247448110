


// tab style version 4 //
$bo-tabs-v4-a-color:               #cccccc !default; 
$bo-tabs-v4-a-color-active:        #222 !default;

$bo-tabs-v4-padding:               rem(25px) rem(5px) !default; 
$bo-tabs-v4-background:            #f1f1f1 !default;
$bo-tabs-v4-background-hover:      #ffffff !default;
$bo-tabs-v4-content-background:    transparent !default;

$bo-tabs-v4-border-color : #cdcdcd !default;
$bo-tabs-v4-content-border:  0 solid $base-border-color;
$bo-tabs-v4-content-padding: rem(15px) 0!default;


