
// Box Modules 
// ========================================================================
$block-module-margin-bottom: 					$grid-gutter-width-base !default;
$block-module-padding  :						rem(20px)!default;
$block-module-border-color: 					0 !default;

$block-module-heading-border: 					#e0e0e0 !default;
$block-module-heading-transform:        		inherit !default;
$block-module-heading-line-height:      		rem(22px) !default;
$block-module-heading-font-family:    			$font-family-tertiary !default;
$block-module-heading-bg:						transparent !default;
$block-module-heading-padding: 					5px 0 !default;
$block-module-heading-font-size:				rem(36px) !default;
$block-module-heading-font-weight:				700 !default;
$block-module-heading-color:					$text-color-primary !default;





/*  
 * LAYOUT VARIABLES 
 */

// Header
// ========================================================================
$header-bg:  						#ffffff !default;
$header-margin:  				    0 !default;
$header-padding:  				    0 !default;


$header-logo-margin:  				0 !default;
$header-logo-height:  				51px !default;
$header-logo-width:  				162px !default;

$header-text-color-search:			#d3d3d3 !default;
$header-text-color-cart:			#363636 !default;
$header-text-color-total:			#363636 !default;

// Topbar 
// ========================================================================
$topbar-bg: 							#202020 !default;
$topbar-color: 							#999999 !default;
$topbar-font-size: 						12px !default;
$topbar-font-family:					$font-family-secondary !default;
$topbar-font-weight:          			400 !default;
$topbar-link-color: 					#999999 !default;
$topbar-link-hover-color: 				$theme-color-primary !default;

$topbar-padding: 						10px 0 !default;
$topbar-border:               			1px solid #186bbf !default;


// Main menu 

$mainnav-inner-bg:						#131313 !default;




// Footer
// ========================================================================

$footer-bg: 								#1b2126 !default;
$footer-color: 								#666666 !default;


$footer-heading-font-family: 	$font-family-base !default;
$footer-heading-font-size: 		rem(20px)  !default;
$footer-heading-font-weight: 	300 !default;
$footer-heading-color: 			#363636 !default;
$footer-heading-padding:      	0 !default;

$footer-link-color: 			#666666 !default;
$footer-link-hover-color: 		$theme-color-primary !default;
$footer-font-size:   	 		$font-size-base !default;
     
$footer-content-padding:      15px !default;
$footer-content-margin:       0 !default;
$footer-list-padding:         10px 0 !default;
$footer-margin:               20px !default;

 /////// footer top
$footer-top-outside-border:		0 !default;
$footer-top-outside-bg :			#ffd25b !default;
$footer-top-bg: 			 				transparent !default;
$footer-top-padding: 			 		0 !default;
$footer-top-color:						#fff !default;
$footer-top-content-padding:	rem(70px) 0 0px !default;
$footer-top-content-margin:		    0 !default;
$footer-top-color-hover:			$theme-color-primary !default;	
$footer-top-border:					#333333 !default;

/////// footer center 
$footer-center-outside-bg :				rgba(0,0,0,.7) !default;
$footer-center-bg: 			 					transparent !default;
$footer-center-content-padding:		rem(60px) 0 rem(30px)!default;
$footer-center-content-margin:		0 !default;
$footer-center-color:							#6c7d88 !default;
$footer-center-color-hover:				$theme-color-primary !default;	

/////// footer bottom 
$footer-bottom-outside-bg :				transparent !default;
$footer-bottom-bg: 			 					transparent !default;
$footer-bottom-content-padding:		30px 0 0!default;
$footer-bottom-content-margin:		0 !default;
$footer-bottom-color:							$footer-link-color !default;
$footer-bottom-color-hover:				$footer-link-hover-color!default;	

// Powered By / Copyright
// ========================================================================
$power-outside-bg:       			rgba(0,0,0,.75)!default;
$power-bg: 										transparent!default;
$power-color:                 #999999 !default;
$power-link-color:            #6c7d88!default;
$power-link-hover-color:      $theme-color-primary !default;
$power-padding:               30px 0 10px !default;
$power-font-family  : 				$font-family-primary !default;
$power-border-color:					#ebebeb !default;