 

// product label
$product-label-border-color:          #7672cf !default;
$product-label-color :                #fff !default;
$product-label-font-size  :           11px !default;
$product-label-font-family:           $font-family-secondary !default;
$product-label-font-weight:           700!default;

$product-label-new-bg:                $theme-color-primary !default;
$product-label-new-border-color:      $theme-color-primary !default;

$product-label-sale-bg:               #f15a5a !default;
$product-label-sale-border-color:     #f15a5a !default;
$product-label-sale-text-color:       #fff !default;



//---------- Product Elements

$product-price-color:					#ee3030 !default;
$product-reduced-price:					$text-color-secondary !default;

$product-price-font-family:         	$font-family-base !default;
$product-price-font-size:           	14px !default;
$product-price-font-weight:         	700 !default;


$product-new-price-color:           	#ee3030 !default;
$product-new-price-bg-color:	    	#ff5757 !default;
$product-new-price-font-size:       	$product-price-font-size !default;
$product-new-price-weight:          	$product-price-font-weight!default;

$product-special-price-bg-color:		#ff5757 !default;
$product-special-price-color:			#fff !default;
$product-special-price-size:			$product-price-font-size !default;

$product-old-price-color:				$text-color-secondary !default;
$product-old-price-bg-color: 			#56595e !default;
$product-old-price-size:				13px !default;
$product-old-price-font-weight:	  		400 !default;





// Product Block
// ========================================================================
$product-size:                      $font-size-base !default;
$product-bg-color:                  #fff !default;
$product-margin:                    35px;
$product-border-color : 			1px solid transparent !default;
$product-content-bg: 				transparent !default;
$product-content-padding:			30px !default;
$product-container-border:			1px solid transparent !default;
$product-container-bg:              #fff !default;


$product-link-color: 				$text-color-primary !default;
$product-link-hover-color: 			$text-color-secondary !default;
$product-name-color: 				$text-color-primary  !default;
$product-name-hover-color:          $text-color-secondary !default;
$product-name-line-height:          rem(18px) !default;
$product-name-font-family:          $font-family-secondary !default;
$product-name-font-size: 			rem(22px) !default;
$product-name-font-weight: 			300 !default;
$product-name-text-decoration:      none !default;
$product-padding:                   0 !default;



