//-----------------------------------------------------------------------------

//  - Revolution Slider 1.5.3 -

//    Screen Stylesheet

//version:    2.1
//date:       09/18/11
//last update: 06.12.2012
//author:   themepunch
//email:      info@themepunch.com
//website:    http://www.themepunch.com
//-----------------------------------------------------------------------------*/


//-----------------------------------------------------------------------------

//  - Revolution Slider 2.0 Captions -

//    Screen Stylesheet

//version:    1.4.5
//date:       09/18/11
//last update: 06.12.2012
//author:   themepunch
//email:      info@themepunch.com
//website:    http://www.themepunch.com
//-----------------------------------------------------------------------------
@import "../../../../_theme_variables";

@import url(//fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
.layerslider-wrapper {
  position: relative;
  z-index: 9;
}

.rev_slider {
  position: relative;
  overflow: hidden;
  z-index: 9;
}

.bannercontainer {
  position: relative;
  margin: 0px auto;
  overflow: hidden;

  .banner-fullwidth {
    width: 100% !important;
  }

  .fullwidthbanner {
    overflow: hidden!important;
    padding: 0;
    position: relative;
  }

  .banner-fullwidth {
    width: 100% !important;
  }
}
.tp-hide-revslider {
  visibility: hidden !important;
  display: none !important;
}
.tp-caption {
  z-index: 1;
  &.tp-hidden-caption {
    visibility: hidden !important;
    display: none !important;
  }
  &.text-large-black{
    position: absolute;
    text-shadow: none;
    font-weight: 800;
    font-size: 110px;
    line-height: 4.23077rem;
    margin: 0rem;
    border-width: 0rem;
    border-style: none;
    color: #363636;
    letter-spacing: 0;
    font-family: 'ShonarBangla bold';
    text-transform: uppercase; 
  }
   &.text-large-slide2{
    position: absolute;
    text-shadow: none;
    font-weight: 100;
    font-size: rem(80px);
    line-height: rem(55px);
    margin: rem(0px);
    border-width: rem(0px);
    border-style: none;
    color: $white;
    letter-spacing: 0;
    font-family: $font-family-sans-serif;
  }
  &.text-medium-white{
    position: absolute;
    font-weight: 400;
    font-size: rem(40px);
    color: $white;
    letter-spacing: 0;
    font-family: $font-family-sans-serif;
  }
  &.text-small-black{
    position: absolute;
    font-weight: 500;
    font-size: 24px;
    color: #363636;
    letter-spacing: 0;
    font-family: 'Heebo', sans-serif;
  }
  &.text-small-top {
    position: absolute;
    font-weight: 300;
    font-size: 36px;
    color: #363636;
    text-transform: uppercase;
    letter-spacing: 0;
    font-family: 'ShonarBangla';
  }
  &.text-small-slide2{
    position: absolute;
    font-weight: 400;
    font-size: rem(26px);
    color: $white;
    letter-spacing: 0;
    font-family: $font-family-sans-serif;
  }
  &.big_white {
    position: absolute;
    text-shadow: none;
    font-weight: 800;
    font-size: 40px;
    line-height: 40px;
    padding: 1px 0 3px;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-family: $font-family-secondary;
  }  
  &.big_white_border {
    position: absolute;
    text-shadow: none;
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    padding: 10px 25px;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    color: #fff;
    letter-spacing: 0px;
    font-family: $font-family-secondary;
    border: 3px solid #fff;
  }
  &.big_orange {
    position: absolute;
    text-shadow: none;
    font-weight: 400;
    font-size: 36px;
    line-height: 36px;
    padding: 0px 4px;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    letter-spacing: -1.5px;
    color: #fff;
    background-color: #fb7354;
  }
  &.big_black {
    position: absolute;
    text-shadow: none;
    font-weight: 800;
    font-size: 40px;
    line-height: 38px;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    white-space: nowrap;
    color: #6c8dae;
  }
  &.big_black_light {
    position: absolute;
    text-shadow: none;
    font-weight: 300;
    font-size: 40px;
    line-height: 38px;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    white-space: nowrap;
    color: #222222;
  }
  &.medium_grey {
    position: absolute;
    text-shadow: none;
    font-weight: 400;
    font-size: 24px;
    line-height: 20px;
    padding: 2px 4px;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    white-space: nowrap;
    color: #bbbbbb;
  }
  &.small_text {
    position: absolute;
    color: #fff;
    text-shadow: none;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    white-space: nowrap;
    @include rtl-text-align-right();
  }
  &.medium_text {
    position: absolute;
    color: #fff;
    text-shadow: none;
    font-weight: normal;
    font-size: 14px;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    white-space: nowrap;
    text-shadow: none;
    text-align: center;
  }
  &.large_text {
    position: absolute;
    color: #eeeeee;
    text-shadow: none;
    font-weight: bold;
    font-size: 54px;
    line-height: 36px;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    white-space: nowrap;
    text-shadow: none;
    text-transform: uppercase;
    font-family: $font-family-primary;
    text-align: center;
  }
  &.small_black_text {
    position: absolute;
    color: #222222;
    text-shadow: none;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    margin: 0px;
    padding: 3px 50px;
    border-width: 0px;
    letter-spacing: 5px;
    background-color: #e0e0e0;
    border-style: none;
    white-space: nowrap;
    text-transform: uppercase;
  }
  &.large_black_text {
    position: absolute;
    color: #000;
    text-shadow: none;
    font-weight: 100;
    font-size: 60px;
    line-height: 60px;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    white-space: nowrap;
  }
  &.very_large_text {
    position: absolute;
    color: #fff;
    text-shadow: none;
    font-weight: 800;
    font-size: 60px;
    line-height: 60px;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    white-space: nowrap;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    letter-spacing: 0px;
  }
  &.very_large_black_text {
    position: absolute;
    color: #000;
    text-shadow: none;
    font-weight: 800;
    font-size: 60px;
    line-height: 68px;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    white-space: nowrap;
  }
  &.bold_red_text {
    position: absolute;
    color: #d31e00;
    text-shadow: none;
    font-weight: 400;
    font-size: 24px;
    line-height: 20px;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    white-space: nowrap;
  }
  &.bold_brown_text {
    position: absolute;
    color: #a04606;
    text-shadow: none;
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    white-space: nowrap;
  }
  &.bold_green_text {
    position: absolute;
    color: #5b9830;
    text-shadow: none;
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    white-space: nowrap;
  }

  &.very_big_red {
    position: absolute;
    color: #fd020b;
    text-shadow: none;
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
    margin: 0px;
    border-width: 0px;
    text-transform: uppercase;
    border-style: none;
    padding: 0px 4px;
    padding-top: 1px;
    background-color: transparent;
    font-family: $font-family-primary;
  }
  &.very_big_white {
    position: absolute;
    color: #fff;
    text-shadow: none;
    font-weight: 800;
    font-size: 80px;
    line-height: 80px;
    margin: 0px;
    border-width: 0px;
    text-transform: normal;
    border-style: none;
    padding: 0;
    padding-top: 1px;
    background-color: transparent;
    font-family: $font-family-secondary;
  }
  &.very_big_black {
    position: absolute;
    color: #000;
    text-shadow: none;
    font-weight: 700;
    font-size: 90px;
    line-height: 90px;
    margin: 0px;
    border-width: 0px;
    text-transform: uppercase;
    border-style: none;
    white-space: nowrap;
    padding: 0px 4px;
    padding-top: 1px;
    background-color: transparent;
    font-family: $font-family-secondary;
  }
  &.very_big_blue {
    position: absolute;
    color: #44c4cc;
    text-shadow: none;
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
    margin: 0px;
    border-width: 0px;
    text-transform: uppercase;
    border-style: none;
    white-space: nowrap;
    padding: 0px 4px;
    padding-top: 1px;
    background-color: transparent;
    font-family: $font-family-primary;
  }      
  &.large_white {
    position: absolute;
    color: #fff;
    font-size: 32px;
    line-height: 30px;
    font-weight: 300;
    padding: 0;
    font-family: $font-family-secondary;
  }  
  &.large_gray {
    position: absolute;
    color: #535763;
    font-size: 32px;
    line-height: 30px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: $font-family-primary;
  }  
  &.large_black {
    position: absolute;
    color: #000;
    font-size: 32px;
    line-height: 30px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: $font-family-primary;
  }
  &.large_green {
    position: absolute;
    color: #97b415;
    font-size: 32px;
    line-height: 30px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: $font-family-primary;
  }  
  &.large_blue {
    position: absolute;
    color: #45a5ff;
    font-size: 32px;
    line-height: 30px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: $font-family-primary;
  }  
  &.large_orange {
    position: absolute;
    color: #ff8200;
    font-size: 32px;
    line-height: 30px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: $font-family-primary;
  }
  &.medium_yellow {
    position: absolute;
    color: #ffcc00;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    font-weight: 800;
    font-family: $font-family-primary;
    display: block;
  } 
   &.medium_white {
    color: #fff;
    text-shadow: none;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    position: absolute;
    font-family: $font-family-primary;
  }   
  &.medium_orange {
    color: #ff8200;
    text-shadow: none;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    position: absolute;
    font-family: $font-family-primary;
    text-transform: uppercase;
    letter-spacing: -2px;
  }
  &.medium_black {
    color: #666666;
    text-shadow: none;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    position: absolute;
    font-family: $font-family-primary;
  }
  &.small_white {
    color: #fff;
    text-shadow: none;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    position: absolute;
    font-family: $font-family-primary;
    text-transform: normal;
  }
  &.small_gray {
    color: #535763;
    text-shadow: none;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    position: absolute;
    font-family: $font-family-primary;
    text-transform: normal;
  }
  &.small_black {
    color: #000;
    text-shadow: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    position: absolute;
    font-family: $font-family-primary;
    text-transform: uppercase;
  }
   &.light_black {
    color: #666;
    text-shadow: none;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    position: absolute;
    font-family: $font-family-primary;
  }
  &.white {
    color: #fff;
    text-shadow: none;
    font-weight: 300;
    line-height: 18px;
    position: absolute;
    font-family: $font-family-primary;
  }
  &.black {
    color: #000;
    text-shadow: none;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
  }
  &.text-special {
    font-size: 200px;
    color:rgba(0,0,0,.1);
    line-height: 200;
    font-weight: 700;
  }
  &.bg_grey {
      background-color: rgba(255,255,255,0.2);
      color: #fff;
      text-shadow: none;
      font-weight: 600;
      line-height: 14px;
      position: absolute;
      font-family: $font-family-primary;
      padding: 10px 20px;
      text-transform: uppercase;
      @include border-radius(3px);
      @include box-shadow(0 0 9px rgba(0,0,0,0.06));
  }
  .red {
    color: #ee6262;
    font-weight: 700;
  }
  .orange {
    color: #ffe345;
  }
  .pure {
    color: #f09500
  }
  .pink {
    color: #ff1c84;
  }
  .text_center {
    text-align: center;
  }
  hr {
    border: 1px solid #fff;
    @include size(160px,0);
    margin: 0;
  }
  &.border-grey hr {
    border-color: #bbbbbb;
  }
  &.noshadow {
    text-shadow: none;
    color: #eee;
    width: 500px;
    white-space:normal !important;
    font-size: 26px;
  }
}

.tp_inner_padding {
  @include box-sizing (border-box);
  max-height: none !important;
}

.tp-button {
  padding: 0 15px;
  @include border-radius(3px);
  height: 35px;
  cursor: pointer;
  color: #fff !important;
  font-size: 15px;
  line-height: 35px !important;
  letter-spacing: -1px;
  text-transform: uppercase;
}
.btn-slideshow {
  line-height: 25px !important;
  font-size: 11px !important;
  border: 1px solid transparent;
  background: transparent;
  position: relative;
  padding: 10px 25px !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  width: 160px;
  text-align: center;
  @include transition(all 0.3s ease-in-out 0s);
  &:hover {
    cursor: pointer;
  }
}
.btn-outline-white {
  border-color: #fff;
  color: #fff;
}
.btn-outline-conversion {
  border-color: $theme-color-primary;
  color: $theme-color-primary;
}
.btn-white {
  background-color: #fff;
  color: #353535;
}
.btn-blue {
  background-color: #0189d2;
  color: #fff;
}
.btn-yellow {
  background-color: #ffd733;
  color: #fff;
}
.btn-green {
  background-color: #a4c72c;
  color: #fff;
}
.btn-orange {
  background-color: #ff8200;
  color: #fff;
}
.btn-black {
  background-color: #000;
  color: #fff;
}
.btn-red {
  background-color: #e43636;
  color: #fff;
}
.btn-conversion {
  background-color: #c99947;
  color: #fff;
}
/*  btn-slideshow COLORS */
.purchase:hover, .button:hover, .button.big:hover {
  background-position: bottom, 15px 11px;
}



@include media-breakpoint-only(sm) {

  a.button {
    @include transition (none);
  }
}

@include media-breakpoint-only(xs) {

  a.button {
    @include transition (none);
  }
}

.btn {
  &.yellow {
    background: #ffcc00;
    color: #000;
  }
  &.btn-green {
    background-color: #8ac53f;
    color: #fff;
  }
  &.blue {
    background-color: #0866e4;
    color: #fff;
  }
}
.text-hot {
  background-color: #ff8200;
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  @include size(70px,70px);
  color: #fff;
  @include border-radius(50%);
  line-height: 70px;
  text-align: center;
  font-weight: 700;
}
.text-new {
  background-color: #3fc843;
  color: #fff;
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  @include size(70px,70px);
  @include border-radius(50%);
  line-height: 70px;
  text-align: center;
  font-weight: 700;
}

@include media-breakpoint-only(sm) {
  .btn-slideshow {
    padding: 4px 8px 3px;
    line-height: 25px !important;
    font-size: 11px !important;
    font-weight: normal;
  }

  a.btn-slideshow {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
  }
}
@include media-breakpoint-only(xs) {
  .btn-slideshow {
    padding: 2px 5px 2px;
    line-height: 20px !important;
    font-size: 10px !important;
  }

  a.btn-slideshow {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
  }
}
/*  btn-slideshow COLORS */
.btn-slideshow.green, .btn-slideshow:hover.green, .purchase.green, .purchase:hover.green {
  background-color: #f44336;
}

.btn-slideshow.blue, .btn-slideshow:hover.blue, .purchase.blue, .purchase:hover.blue {
  background-color: #1d78cb;
}

.btn-slideshow.red, .btn-slideshow:hover.red, .purchase.red, .purchase:hover.red {
  background-color: #f44336;
}

.btn-slideshow.orange, .btn-slideshow:hover.orange, .purchase.orange, .purchase:hover.orange {
  background-color: #ff7700;
}

.btn-slideshow.darkgrey, .btn-slideshow.grey, .btn-slideshow:hover.darkgrey, .btn-slideshow:hover.grey, .purchase.darkgrey, .purchase:hover.darkgrey {
  background-color: #555;
}

.btn-slideshow.lightgrey, .btn-slideshow:hover.lightgrey, .purchase.lightgrey, .purchase:hover.lightgrey {
  background-color: #888;
}

/* SET THE ANIMATION EVEN MORE SMOOTHER ON ANDROID  */
.tp-simpleresponsive .slotholder *, .tp-simpleresponsive img {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}

/* SOME CAPTION MODIFICATION AT START  */
.tp-simpleresponsive .caption, .tp-simpleresponsive .tp-caption {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  position: absolute;
  visibility: hidden;
}

.tp-simpleresponsive img {
  max-width: none;
}

/*  IE8 HACKS */
.noFilterClass {
  filter: none !important;
}

/* SHADOWS  */
.tp-bannershadow {
  margin-left: auto;
  margin-right: auto;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tp-bannershadow.tp-shadow1 {
  background: url(../img/front_img/shadow1.png) no-repeat;
  background-size: 100%;
  width: 890px;
  height: 60px;
  bottom: -30px;
}

.tp-bannershadow.tp-shadow2 {
  background: url(../img/front_img/shadow2.png) no-repeat;
  background-size: 100%;
  width: 890px;
  height: 110px;
  bottom: -60px;
}

.tp-bannershadow.tp-shadow3 {
  background: url(../img/front_img/shadow3.png) no-repeat;
  background-size: 100%;
  width: 890px;
  height: 60px;
  bottom: -60px;
}

/* FULLSCREEN VIDEO */
.caption.fullscreenvideo {
  left: 0px;
  top: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
}

.caption.fullscreenvideo iframe {
  width: 100% !important;
  height: 100% !important;
}

.tp-caption.fullscreenvideo {
  left: 0px;
  top: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
}

.tp-caption.fullscreenvideo iframe {
  width: 100% !important;
  height: 100% !important;
}

/* NAVIGATION */
/** BULLETS **/
.tpclear {
  clear: both;
}

.tp-bullets {
  z-index: 25;
  position: absolute;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  -ms-transition: opacity 0.2s ease-out;
}

.tp-bullets.hidebullets {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

.tp-bullets.simplebullets.navbar {
  border: 1px solid #666;
  border-bottom: 1px solid #444;
  background: url(../img/front_img/boxed_bgtile.png);
  height: 40px;
  padding: 0px 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.tp-bullets.simplebullets.navbar-old {
  background: url(../img/front_img/navigdots_bgtile.png);
  height: 35px;
  padding: 0px 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.tp-bullets.simplebullets.round .bullet {
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/bullet.png) no-Repeat top left;
  width: 20px;
  height: 20px;
  margin-right: 0px;
  float: left;
  margin-top: -10px;
  margin-left: 3px;
}

.tp-bullets.simplebullets.round .bullet.last {
  margin-right: 3px;
}

.tp-bullets.simplebullets.round-old .bullet {
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/bullets.png) no-Repeat bottom left;
  width: 23px;
  height: 23px;
  margin-right: 0px;
  float: left;
  margin-top: -12px;
}

.tp-bullets.simplebullets.round-old .bullet.last {
  margin-right: 0px;
}

/** SQUARE BULLETS **/
.tp-bullets.simplebullets.square .bullet {
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/bullets2.png) no-Repeat bottom left;
  width: 19px;
  height: 19px;
  margin-right: 0px;
  float: left;
  margin-top: -10px;
}

.tp-bullets.simplebullets.square .bullet.last {
  margin-right: 0px;
}

/** SQUARE BULLETS **/
.tp-bullets.simplebullets.square-old .bullet {
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/bullets2.png) no-Repeat bottom left;
  width: 19px;
  height: 19px;
  margin-right: 0px;
  float: left;
  margin-top: -10px;
}

.tp-bullets.simplebullets.square-old .bullet.last {
  margin-right: 0px;
}

/** navbar NAVIGATION VERSION **/
.tp-bullets.simplebullets.navbar .bullet {
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/bullet_boxed.png) no-Repeat top left;
  width: 18px;
  height: 19px;
  margin-right: 5px;
  float: left;
  margin-top: 10px;
}

.tp-bullets.simplebullets.navbar .bullet.first {
  margin-left: 0px !important;
}

.tp-bullets.simplebullets.navbar .bullet.last {
  margin-right: 0px !important;
}

/** navbar NAVIGATION VERSION **/
.tp-bullets.simplebullets.navbar-old .bullet {
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/navigdots.png) no-Repeat bottom left;
  width: 15px;
  height: 15px;
  margin-left: 5px !important;
  margin-right: 5px !important;
  float: left;
  margin-top: 10px;
}

.tp-bullets.simplebullets.navbar-old .bullet.first {
  margin-left: 0px !important;
}

.tp-bullets.simplebullets.navbar-old .bullet.last {
  margin-right: 0px !important;
}

.tp-bullets.simplebullets .bullet:hover, .tp-bullets.simplebullets .bullet.selected {
  background-position: top left;
}

.tp-bullets.simplebullets.round .bullet:hover, .tp-bullets.simplebullets.round .bullet.selected, .tp-bullets.simplebullets.navbar .bullet:hover, .tp-bullets.simplebullets.navbar .bullet.selected {
  background-position: bottom left;
}

/*  TP ARROWS */
.tparrows {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  -ms-transition: opacity 0.2s ease-out;
}

.tparrows.hidearrows {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

.tp-leftarrow {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/large_left.png) no-repeat top left;
  width: 40px;
  height: 40px;
}

.tp-rightarrow {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/large_right.png) no-repeat top left;
  width: 40px;
  height: 40px;
}

.tp-leftarrow.round {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/small_left.png) no-repeat top left;
  width: 19px;
  height: 14px;
  margin-right: 0px;
  float: left;
  margin-top: -7px;
}

.tp-rightarrow.round {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/small_right.png) no-repeat top left;
  width: 19px;
  height: 14px;
  margin-right: 0px;
  float: left;
  margin-top: -7px;
}

.tp-leftarrow.round-old {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/arrow_left.png) no-repeat top left;
  width: 26px;
  height: 26px;
  margin-right: 0px;
  float: left;
  margin-top: -13px;
}

.tp-rightarrow.round-old {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/arrow_right.png) no-repeat top left;
  width: 26px;
  height: 26px;
  margin-right: 0px;
  float: left;
  margin-top: -13px;
}

.tp-leftarrow.navbar {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/small_left_boxed.png) no-repeat top left;
  width: 20px;
  height: 15px !important;
  min-height: 15px !important;
  float: left;
  margin-right: 6px;
  margin-top: 12px;
}

.tp-rightarrow.navbar {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/small_right_boxed.png) no-repeat top left;
  width: 20px;
  height: 15px !important;
  min-height: 15px !important;
  float: left;
  margin-left: 6px;
  margin-top: 12px;
}

.tp-leftarrow.navbar-old {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/arrowleft.png) no-repeat top left;
  width: 9px;
  height: 16px;
  float: left;
  margin-right: 6px;
  margin-top: 10px;
}

.tp-rightarrow.navbar-old {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/arrowright.png) no-repeat top left;
  width: 9px;
  height: 16px;
  float: left;
  margin-left: 6px;
  margin-top: 10px;
}

.tp-leftarrow.navbar-old:hover, .tp-rightarrow.navbar-old:hover {
  background-position: left -16px;
}

.tp-leftarrow.navbar-old.thumbswitharrow {
  margin-right: 10px;
}

.tp-rightarrow.navbar-old.thumbswitharrow {
  margin-left: 0px;
}

.tp-leftarrow.square {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/arrow_left2.png) no-repeat top left;
  width: 12px;
  height: 17px;
  float: left;
  margin-right: 0px;
  margin-top: -9px;
}

.tp-rightarrow.square {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/arrow_right2.png) no-repeat top left;
  width: 12px;
  height: 17px;
  float: left;
  margin-left: 0px;
  margin-top: -9px;
}

.tp-leftarrow.square-old {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/arrow_left2.png) no-repeat top left;
  width: 12px;
  height: 17px;
  float: left;
  margin-right: 0px;
  margin-top: -9px;
}

.tp-rightarrow.square-old {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url(../img/front_img/arrow_right2.png) no-repeat top left;
  width: 12px;
  height: 17px;
  float: left;
  margin-left: 0px;
  margin-top: -9px;
}

.tp-leftarrow.square-old:hover, .tp-rightarrow.square-old:hover {
  background-position: left -17px;
}

.tp-leftarrow.default {
  z-index: 20;
  cursor: pointer;
  position: relative;
  background-image: url(../img/front_img/large_left.png);
  background-position: 0 0;
  background-color: transparent;
  background-repeat: no-repeat;
  @include size(40px,40px);
  left: -100px;
  @include opacity(0);
}

.tp-rightarrow.default {
  z-index: 20;
  cursor: pointer;
  position: relative;
  background-image: url(../img/front_img/large_right.png);
  background-position: 0 0;
  background-color: transparent;
  background-repeat: no-repeat;
  @include size(40px,40px);
  right: -100px;
  @include opacity(0);
}
.bannercontainer:hover {
  .tp-leftarrow.default {
    @include opacity(1);
    left: 0;
  }
  .tp-rightarrow.default {
    @include opacity(1);
    right: 0;
  }
}
.tp-leftarrow.default:hover, .tp-rightarrow.default:hover {
  background-position: 0 -40px;
  background-color: transparent;
}
.tp-leftarrow:hover, .tp-rightarrow:hover {
  background-position: 0 -14px;
  background-color: transparent;
}

/*  TP THUMBS */
.tp-bullets.tp-thumbs {
  z-index: 1000;
  position: absolute;
  padding: 3px;
  background-color: #fff;
  width: 500px;
  height: 50px;
  /* THE DIMENSIONS OF THE THUMB CONTAINER */
  margin-top: -50px;
}

.fullwidthbanner-container .tp-thumbs {
  padding: 3px;
}

.tp-bullets.tp-thumbs .tp-mask {
  width: 500px;
  height: 50px;
  /* THE DIMENSIONS OF THE THUMB CONTAINER */
  overflow: hidden;
  position: relative;
}

.tp-bullets.tp-thumbs .tp-mask .tp-thumbcontainer {
  width: 5000px;
  position: absolute;
}

.tp-bullets.tp-thumbs .bullet {
  width: 100px;
  height: 50px;
  /* THE DIMENSION OF A SINGLE THUMB */
  cursor: pointer;
  overflow: hidden;
  background: none;
  margin: 0;
  float: left;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  /*filter: alpha(opacity=50);  */
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
}

.tp-bullets.tp-thumbs .bullet:hover, .tp-bullets.tp-thumbs .bullet.selected {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.tp-thumbs img {
  width: 100%;
}

/* TP BANNER TIMER */
.tp-bannertimer {
  width: 100%;
  height: 10px;
  background: green;
  position: absolute;
  z-index: 200;
  @include opacity(0.8);
}

.tp-bannertimer.tp-bottom {
  bottom: 0px !important;
  height: 7px;
}
.tp-bannertimer.tp-top {
  top: 0px !important;
  height: 3px;
}

/* RESPONSIVE SETTINGS */
@include media-breakpoint-only(sm) {
  .responsive .tp-bullets.tp-thumbs {
    width: 300px !important;
    height: 30px !important;
  }

  .responsive .tp-bullets.tp-thumbs .tp-mask {
    width: 300px !important;
    height: 30px !important;
  }

  .responsive .tp-bullets.tp-thumbs .bullet {
    width: 60px !important;
    height: 30px !important;
  }
}
@include media-breakpoint-only(xs) {
  .responsive .tp-bullets {
    display: none;
  }

  .responsive .tparrows {
    display: none;
  }
}
@include media-breakpoint-down(md){
  .btn-v1 a{
    font-size: rem(8px);
  }
}
@include media-breakpoint-down(sm){
  .btn-v1 a{
    font-size: rem(3px);
  }
}
/* BASIC SETTINGS FOR THE BANNER */
.tp-simpleresponsive img {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tp-simpleresponsive a {
  text-decoration: none;
}

.tp-simpleresponsive ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tp-simpleresponsive > ul > li {
  list-stye: none;
  position: absolute;
  visibility: hidden;
}

/*  CAPTION SLIDELINK   **/
.caption.slidelink a div, .tp-caption.slidelink a div {
  width: 10000px;
  height: 10000px;
  background: url(../img/front_img/coloredbg.png) repeat;
}

.tp-loader {
  background: url(../img/front_img/loader.gif) no-repeat 10px 10px;
  background-color: #fff;
  margin: -22px -22px;
  top: 50%;
  left: 50%;
  z-index: 10000;
  position: absolute;
  width: 44px;
  height: 44px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.tp-3d {
  -webkit-transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
}

.caption .btn {
  &:after {
    display: inline-block;
    content: "\f061";
    font-weight: 300;
    margin-left: 10px;
    font-family: $font-icon;
  }
}
